import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled, { keyframes } from "styled-components";


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledSmallButton = styled.button`
padding: 10px;
border-radius: 30px;
border: none;
background-color: var(--primary);
padding: 10px;
font-weight: bold;
font-size: 15px;
color: var(--primary-text);
width: 90px;
height: 30px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
width: 175px;
  @media (min-width: 767px) {
    width: 275px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 100px;
  @media (min-width: 576px) {
    width: 150px;
  }
  @media (min-width: 576px) {
    width: 250px;
  }
  transition: width 0.5s;
`;

export const StyledSocialIcons = styled.img`
  border: none;
  width: 20px;
  @media (min-width: 900px) {
    width: 30px;
  }
  @media (min-width: 1000px) {
    width: 30px;
  }
  
  transition: width 0.5s;
`;

const StyledFAQContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  border: 1px double var(--secondary);
  padding: 9px;
  border-radius: 24px;
`;

const StyledQuestion = styled.div`
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledAnswer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
`;

const StyledPlusIcon = styled.div`
  margin-right: 10px;
  transform: ${props => (props.isExpanded ? "rotate(45deg)" : "rotate(0)")};
  transition: transform 0.5s ease-out;
`;

const StyledFAQ = ({ question, answer }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <StyledFAQContainer>
      <StyledQuestion style={{ fontWeight: "bold" }} onClick={() => setExpanded(!isExpanded)}>
        <StyledPlusIcon isExpanded={isExpanded}>+</StyledPlusIcon>
        {question}
      </StyledQuestion>
      <StyledAnswer style={{ maxHeight: isExpanded ? "690px" : "0" , fontWeight: "normal" , marginTop: "6px", marginLeft: "19px", marginRight: "10px"}}>
        {answer}
      </StyledAnswer>
    </StyledFAQContainer>
  );
};

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const HighlightedText = styled.span`
  color: var(--secondary);
`;

const glowing = keyframes`
  0% {
    text-shadow: 0 0 10px #fff;
  }
  100% {
    text-shadow: 0 0 15px #ff0080;
  }
`;

const GlowingText = styled.span`
  animation: ${glowing} 1s ease-in-out infinite alternate;
`;

const blink = keyframes`
  0% { color: #FFFFFF; }
  50% { color: #FF1DEC; }
  100% { color: #FFFFFF; }
`;

const BlinkingText = styled.span`
  animation: ${blink} 1s linear infinite;
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You can Buy Max. 20 Tokens per transaction`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  var currentCost = ""

  if (Number(data.totalSupply) < 2000) {
    currentCost = "0"
  }else{
    currentCost = "0.0069"
  }
  const claimNFTs = () => {
    // let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;
    // let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    // console.log("Cost: ", totalCostWei);
    // console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(
          (currentCost * mintAmount).toString(),
          "ether"
        ),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Woohoo! you minted ${CONFIG.NFT_NAME}! Visit Opensea.io to view it and Discord to access Members only chat.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.Container jc={"space-between"} ai={"left"} fd={"row"}>
        <StyledLogo alt={"logo2"} src={"/config/images/logo2.png"} />
       <s.Container jc={"right"} ai={"center"} fd={"row"}>
          <StyledLink href="https://twitter.com/larvacucks" target="_blank">
            <StyledSocialIcons alt={"twitter"} src={"/config/images/twitter.png"} />
          </StyledLink>
          <s.SpacerSmall />
          <StyledLink href="https://medium.com/@larvacucks" target="_blank">
            <StyledSocialIcons alt={"medium"} src={"/config/images/medium.png"} />
          </StyledLink>
          <s.SpacerSmall />
          <StyledLink href="https://discord.gg/HGNz5tkHmz" target="_blank">
            <StyledImg style={{height: "20px",width: "100px"}} alt={"discord"} src={"/config/images/discord.png"} />
          </StyledLink>
        </s.Container>
        </s.Container>
        
        
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
        <s.Container flex={1} jc={"center"} ai={"center"}
            style={{
             
              padding: 2,
              borderRadius: 24,
  
            }}
          >
            <StyledImg style={{height: "210px",width: "345px"}} alt={"preview"} src={"/config/images/preview.gif"} />
            <s.SpacerSmall />
            <s.TextDescription
            style={{
              fontSize: 20, textAlign: "center",
            }}
            >
            <p>Get <GlowingText>Life-Time Membership</GlowingText> and spice things up in your life 
            with Cucks, Bulls and Hotwives from around the Globe!</p>

          </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 2,
              borderRadius: 24,
              border: "4px double var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >{blockchain.account === "" ||
          blockchain.smartContract === null ? (
            <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: 24,
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            More than 3000 Larva Cucks Tokens minted!
             </s.TextTitle>
            ) : (
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            > 
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            )}
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            > <BlinkingText>Hurry! Get yours before they're sold out!</BlinkingText>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Larva Cucks Sold Out!
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Get 1 CUCK for 0.0069{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  *Optimised for low gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect and Purchase your token
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT 
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Cuckolding" : "Purchase"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
                <s.SpacerMedium />
                <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            > Or Mint from&nbsp;  
              <StyledLink href="https://etherscan.io/address/0x8c3bc4c8fd5b918c52d115d25d9751492dbd2c60" target="_blank">
              Etherscan </StyledLink>
            </s.TextDescription>
              </>
    
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}
            style={{
             
              padding: 2,
              borderRadius: 24,
              
  
            }}
          >
            <StyledImg style={{height: "210px",width: "345px"}} alt={"preview2"} src={"/config/images/preview2.gif"} />
            <s.TextDescription
            style={{
              fontSize: 20, textAlign: "center",
            }}
            >
              Start the cuckolding revolution with our <GlowingText>Limited Edition One-of-One NFTs</GlowingText>
              <p>Join the community in 4 simple steps!</p>
          </s.TextDescription>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "50%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            FREQUENTLY ASKED QUESTIONS
          </s.TextDescription>
       
          
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
          </s.TextDescription>
        </s.Container>
        
        <s.Container jc={"center"} ai={"center"} style={{ width: "50%" }}>
          <s.TextDescription
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
            }}
          >
        <>
        <StyledFAQ
         question="What are the requirements for joining the LARVA CUCKS Community?"
         answer={<>Ready to join the steamy club of love and exploration? 
         <p>All Adults are welcome, whether you're a seasoned pro in the world of consensual cuckolding and polyamory, 
         or just dipping your toes in for the first time!</p> <p>The only requirement?</p> 
         <p>A willingness to learn, grow, and connect with like-minded individuals.
         And of course, owning a Larva Cucks token - a funny NFT of a pixelated cuck - to show you're one of the cool kids.</p> 
         <p>So, grab your Metamask wallet and mint away, because the journey of a thousand cuckolding miles begins with one token purchase! 
         And remember, there's no turning back - once you're in, you're in for life! </p>
         (But don't worry, you can always re-sell your NFT if you change your mind!)</>}

        />

          <StyledFAQ
           question="What are the Benefits of Larva Cucks token and becoming a Member?"
            answer={
           <>
          <p>1. <HighlightedText>Exclusive Access:</HighlightedText> With your Larva Cuck token / membership token, you'll have exclusive access to a community of individuals who share similar interests in polyamory and consensual cuckolding.</p>
          <p>2. <HighlightedText>Connections:</HighlightedText> The members-only discord provides opportunities for you to make steamy and meaningful connections with others who share your interests.</p>
          <p>3. <HighlightedText>Safe Space:</HighlightedText> You'll be able to join a safe and accepting space where you can discuss and explore your interests without fear of judgement.</p>
          <p>4. <HighlightedText>Educational Resources:</HighlightedText> You'll have access to educational resources, discussions and educational materials on polyamory and consensual cuckolding practices.</p>
          <p>5. <HighlightedText>Meetups:</HighlightedText> Opportunities to meet and connect with other consenting members in person. You can use Discord to connect and schedule meetups.</p>
          <p>6. <HighlightedText>Relationships:</HighlightedText> You'll have the ability to form lasting relationships and connect with other members of the community.</p>
          <p>7. <HighlightedText>Belonging:</HighlightedText> By joining Larva Cucks, you'll have a sense of belonging to a supportive community.</p>
          <p>8. <HighlightedText>Experiences:</HighlightedText> You'll have the opportunity to learn from and share your experiences with other members.</p>
          <p>9. <HighlightedText>Member-Only Content:</HighlightedText> Early access to content that cater to your interests. Which includes early acess to content through newsletters.</p>
          <p>10. <HighlightedText>Limited Edition NFT:</HighlightedText> As a member, you'll receive a limited edition token (NFT) that represents your membership, with a unique and collectible pixelated artwork of a Larva Cuck. 
            With only 5555 pieces available, your token serves as a digital representation of your membership and unique identity in the club.</p>
          </>
      }
    />
    <StyledFAQ
      question="What is the cost and how to purchase?"
      answer={
        <>
          The cost of the token is 0.0069 ETH plus gas fees of the Ethereum network. It's recommended to have 0.01 ETH in your wallet to cover the fees in order to mint 1 Larva Cucks token.
          <s.SpacerXSmall/>
          <p>You can purchase the Exclusive Access Token by following these steps:</p>
          <p>1. Install <StyledLink href="https://metamask.io/download/" target="_blank">Metamask (https://metamask.io/download/)</StyledLink> on your browser.</p>
          <p>2. Fund your Metamask wallet address with Ethereum (ETH) through any Crypto exchange platform.</p>
          <p>3. Press the 'Connect' button on the Larva Cucks website and approve the same in Metamask.</p>
          <p>4. Purchase the token to gain exclusive access to a world of benefits.</p>
          <s.SpacerXSmall/>
          www.larvacucks.xyz is the only official website to mint new tokens. You may also buy already minted tokens from official websites of secondary markets like Opensea, Looksrare, etc linked below.
        </>
      }
    />
    <StyledFAQ
      question="How do I access the Members only Discord chat group?"
      answer={<>After getting your Larva Cucks Token head over to our Official Discord <StyledLink href="https://discord.gg/HGNz5tkHmz" target="_blank">(https://discord.gg/HGNz5tkHmz)</StyledLink>. You can then verify your membership by using the Collab.land bot present in the Discord group. 
      Once verified, you will receive the verified role and access to all channels in the server. If you face any issues you may send us a DM on Twitter. Adhere to the guidelines and rules set by the club to maintain your membership.</>}
    />
     <StyledFAQ
      question="What makes the Larva Cucks tokens Limited Edition One-of-Ones?"
      answer="Hold onto your chastity locks, because these Cuck tokens are about to raise the bar for what it means to be unique and limited edition! 
      With 4 different types of pixelated Larva Cucks, each with their own distinct traits, accessories, and facial expressions, there are innumerable combinations that make each Larva Cuck token a one-of-a-kind work of digital art. 
      Whether you're rocking a normal Larva Cuck with a chastity lock, a playful Ape cuck with a banana, a rare Zombie cuck with a carrot, or the ultra-rare Alien cuck with an eggplant, you can rest assured that you've got a NFT that's truly out of this world. 
      And with only 5555 generated tokens, you'll be part of an exclusive club of enthusiasts who know how to keep it kinky and limited!"
    />
    <StyledFAQ
      question="Can I gift Larva Cucks Tokens?"
      answer="Absolutely! Yes, you can share the love of pixelated cucks with someone special! With the magic of blockchain technology, you can gift Larva Cuck Tokens (NFTs) to your loved ones via secondary markets like OpenSea, Looksrare, and more. 
      Simply mint your token through our website, hop on your trusty Metamask wallet, and send that loveable Larva Cuck directly to the receiver's Metamask wallet address. It's that easy! Or as we like to say, spreading the Larva Cucks love, one pixel at a time."
    />
    <StyledFAQ
      question="Can I re-sell my Larva Cucks Token?"
      answer="Yes, you can re-sell your Larva Cucks Token (NFT) on secondary markets like OpenSea, Looksrare, etc at the price of your choice."
    />
    <StyledFAQ
     question="Can I get a refund for my membership?"
     answer="No, memberships cannot be refunded. However, you can re-sell your NFT on secondary markets if you no longer wish to continue your membership."
    />
    <StyledFAQ
     question="Can I cancel my membership?"
     answer="Memberships cannot be cancelled. However, if you violate the guidelines and rules, you may be banned from the Discord group/club."
    />
    <StyledFAQ
     question="Can I use any other Wallet to mint Larva Cucks NFT?"
     answer="Users can only mint the Larva Cucks token using Metamask wallets."
    />
     <StyledFAQ
     question="What are Larva Cucks?"
     answer="Larva Cucks started in Feb of 2022 as a meme token, which intended to be a fun play on other pixelated NFTs like Chads, Lads, Punks etc. 
     We made it CC0 and 'for the culture'. We wished to spread laughter through our funny little tokens. However as of Feb 2023, we're looking forward to transition into something larger and meaningful.
     To provide a safe online space to the consensual cuckolding community, polyamory community through memberships, and to create awareness and sex positivity in the masses through our NFTs."
    />
    <StyledFAQ
     question="Why do we charge for Discord Access Tokens/NFTs?"
     answer={<>The tokens are more than just Discord Access Tokens, they also act as your digital identity which you can proudly display on your social media profiles. 
     <s.SpacerXSmall/> <p>At the same time, the Larva Cucks tokens are very nominally priced at 0.0069 ETH (i.e. $11.38 USD as of Feb 2023).The small cost helps us filter and enroll only serious and verified members into the Discord group.</p>  
     <s.SpacerXSmall/> <p>It also compensates the creators of the Tokens fairly for the time and effort put in till date to create and manage the tokens and the community on the blockchain.  
     It is important to note that the initial 2000 tokens were on free mint for the 'degen NFT community'.</p></>}
    />
  </> 
  </s.TextDescription>
  <s.SpacerLarge />
        </s.Container>
        <s.Container jc={"center"} ai={"center"} fd={"row"}style={{display: "flex",flexWrap: "wrap",alignItems: "center",justifyContent: "center"}}>
            <s.TextDescription style={{fontSize: 20}}> Also Available on </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink href="https://opensea.io/collection/larva-cucks" target="_blank">
            <StyledImg style={{height: "35px",width: "150px"}} alt={"opensea"} src={"/config/images/opensea.png"} />
            </StyledLink>
            <s.SpacerSmall />
            <s.SpacerMedium />
            <StyledLink href="https://looksrare.org/collections/0x8c3bC4c8fd5b918C52d115d25d9751492DbD2C60" target="_blank">
            <StyledImg style={{height: "30px",width: "150px"}} alt={"looksrare"} src={"/config/images/looksrare.png"} />
            </StyledLink>
            <s.SpacerSmall />
            <StyledLink href="https://x2y2.io/collection/larva-cucks/items" target="_blank">
            <StyledImg style={{height: "30px",width: "150px"}} alt={"x2y2"} src={"/config/images/x2y2.svg"} />
            </StyledLink>
          </s.Container>
          <s.SpacerLarge />
          <s.TextDescription > © 2022-2023 Larva Cucks </s.TextDescription> 
      </s.Container>
    </s.Screen>
  );
}

export default App;
